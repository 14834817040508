<script setup>
import { useAccount } from "~/composables/useAccount";
import { useAppSetup } from "~/composables/useAppSetup";

const { activeAccount } = useAccount();

const privacyPolicyDialog = ref(false);
const termsOfUseDialog = ref(false);
const menuLeftDialog = ref(false);

onGlobal("privacy-policy-dialog", () => {
  privacyPolicyDialog.value = true;
});
onGlobal("terms-of-use-dialog", () => {
  termsOfUseDialog.value = true;
});
onGlobal("menu-left-mobile", () => {
  menuLeftDialog.value = true;
});

onBeforeMount(() => {
  const { setupSettings } = useAppSetup();
  setupSettings();
});

</script>

<template>
  <ClientOnly>
    <div class="index-wrapper">
      <!-- <V-Header /> -->
      <div class="index-main">
        <V-Banner />
        <V-Courses />
        <V-About />

        <V-RequestFormWithEmail
          v-if="activeAccount?.account_config_landing?.requests_form_with_email"
        />
        <V-RequestForm v-else />
      </div>
    </div>
    <!-- <V-Footer /> -->
    <v-privacy-policy-dialog
      v-model:dialog="privacyPolicyDialog"
    ></v-privacy-policy-dialog>
    <v-terms-of-use-dialog v-model:dialog="termsOfUseDialog"></v-terms-of-use-dialog>
    <v-menu-mobile-left-dialog
      v-model:dialog="menuLeftDialog"
    ></v-menu-mobile-left-dialog>
  </ClientOnly>
</template>
<style>
/* @import "assets/css/index.css"; */
.index-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.index-main {
  flex: 1 1 auto;
}
</style>
